.btn_red {
  background: #CE0F18;
  width: 210px;
  color: white;
  height: 62px;
  font-weight: bold;
  cursor: pointer;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .btn_red:hover {
    box-shadow: 0px 5px 5px rgba(139, 139, 139, 0.29); }

.btn_white {
  background: #FFFFFF;
  width: 210px;
  color: #CE0F18;
  height: 62px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px; }
  .btn_white:hover {
    box-shadow: 0px 5px 5px rgba(139, 139, 139, 0.29); }
