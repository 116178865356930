.footer {
  margin-top: 70px; }
  .footer_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #CE0F18;
    padding: 40px; }
    @media (max-width: 1200px) {
      .footer_form {
        align-items: center; } }
    @media (max-width: 600px) {
      .footer_form {
        flex-direction: column; } }
    .footer_form .input_wrapper {
      display: flex;
      flex-wrap: wrap; }
      @media (max-width: 1200px) {
        .footer_form .input_wrapper {
          flex-direction: column; } }
      @media (max-width: 500px) {
        .footer_form .input_wrapper {
          width: 100%;
          padding: 0 20px; } }
      @media (max-width: 1200px) {
        .footer_form .input_wrapper_item {
          margin-bottom: 20px; } }
    .footer_form__title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #F2F2F2;
      margin-bottom: 10px; }
    .footer_form_input {
      border: 1px solid #FFFFFF;
      height: 60px;
      width: 300px;
      padding: 10px 15px;
      margin-right: 25px; }
      @media (max-width: 600px) {
        .footer_form_input {
          margin-right: 0; } }
      @media (max-width: 500px) {
        .footer_form_input {
          width: 100%; } }
      .footer_form_input input {
        background: #CE0F18;
        border: none;
        width: 100%;
        height: 100%;
        color: #F2F2F2; }
        .footer_form_input input::placeholder {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #F2F2F2; }
    .footer_form_btn {
      padding: 20px; }
      @media (max-width: 500px) {
        .footer_form_btn {
          width: 100%;
          padding: 0 20px; }
          .footer_form_btn .btn_white {
            width: 100%; } }
  .footer_top {
    padding-top: 50px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around; }
    .footer_top > div {
      margin-right: 20px; }
      @media (max-width: 1000px) {
        .footer_top > div {
          margin-bottom: 20px; } }
      .footer_top > div:last-child {
        margin-right: 0; }
    .footer_top_sections a {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
      margin-right: 15px;
      cursor: pointer; }
      .footer_top_sections a:last-child {
        margin-right: 0; }
    .footer_top_email a {
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 30px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      flex-wrap: nowrap; }
      @media (max-width: 500px) {
        .footer_top_email a {
          font-size: 15px; } }
      .footer_top_email a img {
        margin-right: 25px; }
  .footer_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    width: 60%; }
    @media (max-width: 1000px) {
      .footer_bottom {
        flex-direction: column;
        width: 100%; } }
    .footer_bottom_container {
      display: flex;
      justify-content: flex-end;
      width: 100%; }
      @media (max-width: 1000px) {
        .footer_bottom_container {
          justify-content: center; } }
    .footer_bottom_privacy {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #F2F2F2; }
      @media (max-width: 1000px) {
        .footer_bottom_privacy {
          margin-bottom: 20px; } }

input[type="submit"] {
  border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #CE0F18 inset;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: #FFFFFF; }
