.header {
  background-position: 100% 100%;
  background-size: cover; }
  @media (max-width: 1000px) {
    .header {
      padding: 0; } }
  @media (max-width: 800px) {
    .header {
      background-position: center; } }
  .header_top {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 35px; }
    @media (max-width: 1000px) {
      .header_top {
        display: none; } }
    .header_top__logo {
      width: 20%; }
    .header_top_sections {
      display: flex;
      justify-content: space-between;
      width: 60%; }
      @media (max-width: 1000px) {
        .header_top_sections {
          flex-direction: column; } }
      .header_top_sections__item {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        cursor: pointer;
        margin-right: 20px; }
    .header_top__button {
      width: 20%;
      display: flex;
      justify-content: right; }
  .header_title {
    text-align: center;
    padding-top: 5vw;
    padding-bottom: 20vw; }
    @media (max-width: 1000px) {
      .header_title {
        padding-top: 100px;
        padding-bottom: 5vw; } }
    @media (max-width: 450px) {
      .header_title {
        text-align: start;
        padding: 100px 25px 5vw 25px; }
        .header_title .header_title__1 {
          font-size: 14px; }
        .header_title .header_title__3 {
          font-size: 16px;
          padding: 0; } }
    .header_title__1 {
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 25px;
      letter-spacing: 4.49348px;
      text-transform: uppercase;
      color: #CE0F18;
      margin-bottom: 60px; }
      @media (max-width: 600px) {
        .header_title__1 {
          margin-bottom: 20px; } }
      @media (max-width: 500px) {
        .header_title__1 {
          margin-bottom: 5px; } }
    .header_title__2 {
      font-family: 'Spantaran', sans-serif;
      font-weight: 400;
      font-size: 100px;
      line-height: 72px;
      text-transform: capitalize;
      color: #FFFFFF;
      margin-bottom: 40px; }
      @media (max-width: 1000px) {
        .header_title__2 {
          font-size: 60px; } }
      @media (max-width: 600px) {
        .header_title__2 {
          margin-bottom: 20px;
          font-size: 40px;
          white-space: nowrap; } }
      @media (max-width: 500px) {
        .header_title__2 {
          margin-bottom: 5px; } }
      @media (max-width: 500px) {
        .header_title__2 {
          margin-bottom: 5px; } }
      @media (max-width: 400px) {
        .header_title__2 {
          font-size: 30px; } }
    .header_title__3 {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 72px;
      color: #FFFFFF; }
      @media (max-width: 600px) {
        .header_title__3 {
          font-size: 22px;
          padding: 0 20px; } }
      @media (max-width: 500px) {
        .header_title__3 {
          margin-bottom: 5px;
          line-height: 25px; } }
  .header_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 70px;
    width: 50%; }
    @media (max-width: 1000px) {
      .header_bottom {
        width: 100%;
        justify-content: center; }
        .header_bottom .social {
          width: auto;
          position: absolute;
          right: 2%; }
        .header_bottom .social_follow-us {
          display: none; } }
    @media (max-width: 600px) {
      .header_bottom {
        padding-bottom: 30px; } }
    @media (max-width: 500px) {
      .header_bottom {
        margin-bottom: 5px; }
        .header_bottom .social img {
          width: 26px;
          height: 26px; }
        .header_bottom .social div {
          margin-right: 5px; } }
    .header_bottom_container {
      width: 100%;
      display: flex;
      justify-content: flex-end; }
    .header_bottom .scroll_btn {
      font-style: normal;
      font-weight: 700;
      font-size: 13.4804px;
      line-height: 16px; }
      .header_bottom .scroll_btn a {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #FFFFFF; }
      .header_bottom .scroll_btn__arrow {
        font-size: 20px; }
  .header_mob {
    display: none;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    background: #030E1E;
    height: 55px; }
    @media (max-width: 1000px) {
      .header_mob {
        display: block; } }
    .header_mob__logo {
      display: flex;
      align-items: center; }
      .header_mob__logo img {
        width: 106px;
        height: 33px; }
      .header_mob__logo .btn_red {
        height: 55px;
        padding: 0;
        margin-left: 20px; }
        @media (max-width: 450px) {
          .header_mob__logo .btn_red {
            width: auto;
            padding: 0 20px; } }
    .header_mob_top {
      display: flex;
      justify-content: space-between; }
    .header_mob_nav {
      opacity: 0;
      background: #1A2332;
      padding: 20px;
      width: 300px;
      height: 100vh;
      transition: 0.2s all;
      position: fixed;
      left: -110%; }
      .header_mob_nav_sections {
        display: flex;
        flex-direction: column; }
        .header_mob_nav_sections a {
          color: white;
          font-size: 25px;
          cursor: pointer;
          margin-bottom: 40px; }
      .header_mob_nav .social {
        margin-bottom: 20px; }
  .header_hamburger {
    overflow: hidden;
    position: relative;
    width: 55px;
    height: 55px;
    background: #030E1E;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .header_line {
    transition: all .2s;
    position: absolute;
    width: 31px;
    height: 3px;
    border-radius: 18px;
    margin: 4px 0;
    cursor: pointer;
    background: #FFFFFF; }
    .header_line_1 {
      top: 16px;
      left: 20px; }
    .header_line_2 {
      top: 24px;
      left: 20px; }
    .header_line_3 {
      top: 32px;
      left: 20px; }
  .header.active .header_line_1 {
    top: 24px;
    left: 20px;
    transform: rotate(45deg); }
  .header.active .header_line_2 {
    left: -100px; }
  .header.active .header_line_3 {
    top: 24px;
    left: 20px;
    transform: rotate(-45deg); }
  .header.active .header_mob_nav {
    opacity: 1;
    left: 0;
    top: 55px;
    z-index: 3; }

.social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 230px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF; }
  .social div {
    margin-right: 15px; }
  .social img {
    width: 32px;
    height: 32px;
    margin-right: 10px; }
  .social .social_follow-us {
    white-space: nowrap; }
