.section {
  margin-top: 5%; }
  @media (max-width: 1000px) {
    .section {
      margin-top: 100px; } }
  @media (max-width: 600px) {
    .section {
      margin-top: 50px; } }
  .section_top {
    display: flex; }
    @media (max-width: 1000px) {
      .section_top {
        flex-direction: column; } }
  .section_title {
    width: 40%;
    padding: 50px 10% 5% 10%;
    margin-left: 50px;
    background-repeat: no-repeat;
    background-position: 0% 10%; }
    @media (max-width: 1400px) {
      .section_title {
        width: 50%;
        padding: 5%; } }
    @media (max-width: 1200px) {
      .section_title {
        padding: 20px; } }
    @media (max-width: 1000px) {
      .section_title {
        width: 90%;
        padding-left: 150px; } }
    @media (max-width: 700px) {
      .section_title {
        margin-left: 10px; } }
    @media (max-width: 600px) {
      .section_title {
        padding-left: 60px;
        padding-top: 30px;
        background-size: 20%;
        width: 100%;
        margin: 0; } }
    .section_title__1 {
      font-weight: 800;
      font-size: 13.4804px;
      line-height: 17px;
      letter-spacing: 4.49348px;
      text-transform: uppercase;
      color: #CE0F18;
      margin-bottom: 20px; }
    .section_title__2 {
      font-family: 'Spantaran', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 80px;
      line-height: 92px;
      color: #FFFFFF;
      margin-bottom: 20px; }
      @media (max-width: 700px) {
        .section_title__2 {
          font-size: 40px; } }
      @media (max-width: 450px) {
        .section_title__2 {
          line-height: 130%; } }
    .section_title__3 {
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 130%;
      color: #FFFFFF; }
      @media (max-width: 450px) {
        .section_title__3 {
          font-size: 18px; } }
  .section_img {
    width: 60%;
    height: auto;
    position: relative; }
    @media (max-width: 1400px) {
      .section_img {
        width: 50%; } }
    @media (max-width: 1000px) {
      .section_img {
        width: 100%; } }
    .section_img img {
      width: 100%; }
