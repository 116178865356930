.section01_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px; }
  @media (max-width: 600px) {
    .section01_text {
      margin-top: 50px; } }
  .section01_text__1 {
    width: 60%;
    font-family: 'Spantaran', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 46px;
    text-align: center;
    text-transform: uppercase;
    color: #CE0F18; }
    @media (max-width: 600px) {
      .section01_text__1 {
        width: 90%;
        font-size: 30px; } }
    @media (max-width: 450px) {
      .section01_text__1 {
        font-size: 24px; } }
  .section01_text__2 {
    font-family: 'Spantaran', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 92px;
    color: #FFFFFF;
    margin: 48px 0; }
    @media (max-width: 600px) {
      .section01_text__2 {
        font-size: 40px;
        margin: 28px 0; } }

.section01_cars {
  display: flex;
  flex-wrap: wrap; }
  .section01_cars__item {
    width: 25%;
    height: auto;
    position: relative; }
    @media (max-width: 900px) {
      .section01_cars__item {
        width: 50%; } }
    @media (max-width: 450px) {
      .section01_cars__item {
        max-height: 260px; } }
    .section01_cars__item:hover .section01_cars__item-btn {
      opacity: 1; }
    .section01_cars__item img {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      object-fit: cover; }
    .section01_cars__item-btn {
      padding: 60% 10% 40% 10%;
      text-align: center;
      opacity: 0; }
      @media (max-width: 600px) {
        .section01_cars__item-btn {
          padding: 50% 10% 40% 10%; }
          .section01_cars__item-btn .btn_red {
            width: auto;
            transform: translateY(0px); } }
      .section01_cars__item-btn button {
        transform: translateY(15px); }
    .section01_cars__item-name {
      font-family: 'Spantaran', sans-serif;
      margin-bottom: 5%;
      margin-left: auto;
      margin-right: auto;
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 35px;
      color: #FFFFFF;
      max-width: 215px;
      width: 100%; }
      @media (max-width: 600px) {
        .section01_cars__item-name {
          position: absolute;
          bottom: 5%;
          left: 5%; } }
      @media (max-width: 450px) {
        .section01_cars__item-name {
          font-size: 20px; } }

.section02 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .section02_item {
    width: 32.5%;
    max-height: 600px;
    margin-bottom: 20px; }
    @media (max-width: 1000px) {
      .section02_item {
        width: 48%; } }
    .section02_item_title {
      background-repeat: no-repeat; }
      @media (max-width: 600px) {
        .section02_item_title {
          width: 100%;
          padding-left: 60px;
          background-size: 20%; } }
    .section02_item img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    @media (max-width: 1000px) {
      .section02_item_video {
        width: 100%; }
        .section02_item_video iframe {
          height: 500px; } }
    @media (max-width: 600px) {
      .section02_item_video iframe {
        height: 300px; } }
    .section02_item__wrapper {
      margin-top: 30%; }
      @media (max-width: 600px) {
        .section02_item__wrapper {
          margin-top: 0%; } }

@media (max-width: 1000px) {
  .section03 .section_top {
    flex-direction: column-reverse; } }

.section03 .logo {
  position: absolute;
  width: 15vw;
  left: 60%;
  top: 10%; }
  @media (max-width: 450px) {
    .section03 .logo {
      width: 25vw; } }

.section03_choose {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background-color: #CE0F18;
  padding: 37px 0; }
  @media (max-width: 600px) {
    .section03_choose {
      margin-top: 40px; } }
  .section03_choose__title {
    font-family: 'Spantaran', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 92px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF; }
    @media (max-width: 600px) {
      .section03_choose__title {
        font-size: 40px; } }

.section03_partners__title {
  margin-top: 150px;
  margin-bottom: 60px;
  text-align: center; }
  @media (max-width: 600px) {
    .section03_partners__title {
      margin-top: 50px; } }

.section03_partners_wrapper {
  display: flex;
  align-items: center; }
  .section03_partners_wrapper div {
    width: 33%;
    text-align: center; }
    .section03_partners_wrapper div img {
      cursor: pointer; }

.section04_swiper_slide {
  width: 100%;
  height: 100%;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover; }
  .section04_swiper_slide .slide_title {
    padding: 15%; }
    @media (max-width: 600px) {
      .section04_swiper_slide .slide_title {
        padding: 5%; } }
    .section04_swiper_slide .slide_title__1 {
      font-family: 'Spantaran', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 80px;
      line-height: 92px;
      color: #CE0F18;
      margin-bottom: 50px; }
      @media (max-width: 600px) {
        .section04_swiper_slide .slide_title__1 {
          font-size: 40px;
          margin-bottom: 0px; } }
      @media (max-width: 450px) {
        .section04_swiper_slide .slide_title__1 {
          line-height: 46px; } }
    .section04_swiper_slide .slide_title__2 {
      font-family: 'Spantaran', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 46px;
      color: #FFFFFF;
      margin-bottom: 30px; }
      @media (max-width: 600px) {
        .section04_swiper_slide .slide_title__2 {
          font-size: 30px;
          margin-bottom: 15px; } }
      @media (max-width: 450px) {
        .section04_swiper_slide .slide_title__2 {
          font-size: 24px;
          line-height: 28px; } }
    .section04_swiper_slide .slide_title__3 {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 130%;
      color: #FFFFFF;
      margin-bottom: 15px; }
      @media (max-width: 600px) {
        .section04_swiper_slide .slide_title__3 {
          font-size: 20px; } }
      @media (max-width: 450px) {
        .section04_swiper_slide .slide_title__3 {
          font-size: 18px;
          line-height: 130%; } }
      .section04_swiper_slide .slide_title__3 img {
        width: auto;
        height: auto; }
    .section04_swiper_slide .slide_title_links {
      transform: translateY(50px);
      display: flex;
      justify-content: space-around;
      align-items: center; }
      @media (max-width: 600px) {
        .section04_swiper_slide .slide_title_links {
          transform: translateY(0px); } }
      @media (max-width: 400px) {
        .section04_swiper_slide .slide_title_links {
          flex-direction: column-reverse; }
          .section04_swiper_slide .slide_title_links a {
            margin-bottom: 10px; } }
      .section04_swiper_slide .slide_title_links__1 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
        /* or 31px */
        text-decoration-line: underline;
        color: #FFFFFF; }
      .section04_swiper_slide .slide_title_links__2 {
        text-align: center;
        padding-top: 20px; }

.section04_shop {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 80px;
  background: #1A2332;
  padding: 40px 0; }
  @media (max-width: 600px) {
    .section04_shop {
      margin-top: 40px; } }
  .section04_shop_title {
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    margin-right: 30px; }
    .section04_shop_title__1 {
      font-family: 'Spantaran', sans-serif;
      font-size: 50px;
      line-height: 58px;
      text-align: center;
      text-transform: uppercase; }
      @media (max-width: 600px) {
        .section04_shop_title__1 {
          font-size: 40px;
          line-height: 46px; } }
    .section04_shop_title__2 {
      font-size: 30px;
      line-height: 130%; }
      @media (max-width: 800px) {
        .section04_shop_title__2 {
          padding: 30px 0; } }
      @media (max-width: 600px) {
        .section04_shop_title__2 {
          font-size: 30px; } }

.section04 .swiper_btn {
  left: 100px; }
  @media (max-width: 1400px) {
    .section04 .swiper_btn {
      left: 0; } }

@media (max-width: 1000px) {
  .section05 .section_top {
    flex-direction: column-reverse; } }

.section05_swiper_slide {
  height: auto;
  position: relative; }
  .section05_swiper_slide__title {
    position: absolute;
    font-family: 'Spantaran';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 69px;
    color: #FFFFFF;
    left: 10%;
    bottom: 10%;
    overflow: hidden; }
    @media (max-width: 1400px) {
      .section05_swiper_slide__title {
        font-size: 40px; } }
    @media (max-width: 1200px) {
      .section05_swiper_slide__title {
        font-size: 30px;
        line-height: 35px; } }
    @media (max-width: 450px) {
      .section05_swiper_slide__title {
        font-size: 20px;
        line-height: 25px; } }
  .section05_swiper_slide .img_bg {
    height: 100%; }
    .section05_swiper_slide .img_bg::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.6);
      z-index: 1; }
  .section05_swiper_slide img {
    width: 98%;
    height: 100%;
    object-fit: cover; }

.section05 .swiper_btn {
  left: -100px; }
  @media (max-width: 1400px) {
    .section05 .swiper_btn {
      left: 0; } }

.nav {
  position: fixed;
  top: 40%;
  right: 2%;
  z-index: 100; }
  @media (max-width: 1000px) {
    .nav {
      display: none; } }
  .nav_item {
    display: flex; }
    .nav_item__title {
      width: 100%;
      cursor: pointer;
      font-size: 20px;
      padding-bottom: 20px;
      text-align: end;
      color: white; }
    .nav_item__pagination {
      margin-left: 5px;
      height: 100%; }
