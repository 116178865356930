.swiper_btn {
  position: relative;
  top: 100px;
  width: 100%; }
  @media (max-width: 1000px) {
    .swiper_btn {
      position: static; } }
  .swiper_btn .swiper-pagination {
    left: 60px;
    position: relative; }
    .swiper_btn .swiper-pagination-progressbar {
      width: calc(100% - 120px);
      height: 2px;
      background: #323232; }
      .swiper_btn .swiper-pagination-progressbar-fill {
        background-color: white; }

.swiper-button-next {
  position: absolute;
  right: 0;
  width: 56px;
  height: 56px;
  background: rgba(255, 255, 255, 0.2); }
  .swiper-button-next:after {
    font-size: 18px;
    color: #737988; }

.swiper-button-prev {
  position: absolute;
  left: 0;
  width: 56px;
  height: 56px;
  background: rgba(255, 255, 255, 0.2); }
  .swiper-button-prev:after {
    font-size: 18px;
    color: #737988; }
